<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotEquipment' }">设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>绑定子设备</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <div class="form-title">设备信息</div>
            <el-button type="primary" size="small" @click="bindModel">选择设备</el-button>
          </div>
          <el-table :data="deviceTableData" style="width: 100%">
            <el-table-column prop="waterworks" label="水厂">
              <template slot-scope="scope">
                {{ scope.row.waterworks?scope.row.waterworks:'-' }}
              </template>
            </el-table-column>
            <el-table-column prop="position" label="泵房编号">
              <template slot-scope="scope">
                {{ scope.row.position?scope.row.position:'-' }}
              </template>
            </el-table-column>
            <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
            <el-table-column prop="eid" label="设备号"></el-table-column>
            <el-table-column prop="deviceTypeCode" label="设备类型">
              <template slot-scope="scope">
                <span v-show="scope.row.deviceTypeCode=='Direct'">直连设备</span>
                <span v-show="scope.row.deviceTypeCode=='GatewaySub'">网关子设备</span>
                <span v-show="scope.row.deviceTypeCode=='Gateway'">网关</span>
              </template>
            </el-table-column>
            <el-table-column prop="model" label="设备型号">
              <template slot-scope="scope">
                {{ scope.row.model?scope.row.model:'-' }}
              </template>
            </el-table-column>
            <el-table-column prop="cardNumber" label="物联网卡号">
              <template slot-scope="scope">
                {{ scope.row.cardNumber?scope.row.cardNumber:'-' }}
              </template>
            </el-table-column>
            <el-table-column prop="equipmentBrandCode" label="设备品牌"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span v-show="scope.row.status==0">正常</span>
                <span v-show="scope.row.status==1">异常</span>
              </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注">
              <template slot-scope="scope">
                {{ scope.row.remarks?scope.row.remarks:'-' }}
              </template>
            </el-table-column>
            <el-table-column prop="deviceAlias" label="设备别名" width="180px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.deviceAlias" placeholder="请输入设备别名"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <div>
                  <span @click="dltDevice(scope.row)" style="color:red;cursor:pointer;">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/iotEquipment')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-drawer
          title="网关子设备绑定"
          :with-header="false"
          :visible.sync="deviceDrawer"
          direction="rtl"
          size="1100px">
          <div class="drawer-title">网关子设备绑定</div>
          <div class="drawer-content table-tree">
            <div class="tree">
              <div class="tree-title">供应商</div>
              <el-tree
                :data="supplierData"
                node-key="supplierCode"
                @node-click="supplierClick"
                :props="supplierProps"
                :default-checked-keys="[supplierCode]"
                highlight-current
                :current-node-key="supplierCode"
                ref="supplierTree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px">{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            <div class="tree">
              <div class="tree-title">品牌</div>
              <el-tree
                :data="brandData"
                node-key="equipmentBrandCode"
                @node-click="brandClick"
                :props="brandProps"
                :default-checked-keys="[equipmentBrandCode]"
                highlight-current
                :current-node-key="equipmentBrandCode"
                ref="brandTree"
                v-loading="brandLoading" element-loading-spinner="el-icon-loading">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px">{{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableDataCp" height="700" v-loading="tableSubDeviceLoading" element-loading-spinner="el-icon-loading">
                <el-table-column label="勾选" width="70">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.checked" @change="checkeChange(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="equipmentName" label="设备名称"></el-table-column>
                <el-table-column prop="eid" label="设备号"></el-table-column>
              </el-table>
            </div>
          </div>
          <div class="drawer-bottom">
            <el-button size="small" type="primary" @click="deviceDrawer=false">取消</el-button>
            <el-button
              size="small"
              type="primary"
              @click="deviceDrawerOK"
              class="drawer-bottom-ok">确 定</el-button>
          </div>
        </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        loading: false,
        row: {},
        form: {},
        rules: {
            eid:[{ required: true, message: '必填项', trigger: 'blur' }],
        },
        // 子设备绑定数据
        supplierProps: {
            children: 'childTree',
            label: 'supplier'
        },
        supplierCode: '',
        brandProps: {
            children: 'childTree',
            label: 'equipmentBrand'
        },
        equipmentBrandCode: '',
        deviceDrawer: false,
        supplierData: [],//供应商数据
        brandData: [],//品牌数据
        brandLoading: false,//品牌Loading
        tableSubDeviceLoading: false,//列表Loading
        tableDataCp: [],
        tableSelectList: [],
        deviceTableData: [],
        eid: '',//网关设备eid
    };
  },
  methods: {
    onSubmit() {
      if(this.deviceTableData.length==0) {
        return this.$message.error('请选择设备')
      }
      for(let i=0;i<this.deviceTableData.length;i++) {
        if(!this.deviceTableData[i].deviceAlias) {
          return this.$message.error('设备别名不能为空')
        }
      }

      let subInfo = []
      this.deviceTableData.forEach(v=>{
        subInfo.push({
          subEid: v.eid,
          deviceAlias: v.deviceAlias,
        })
      })
      this.loading = true
      this.$ajax.post('gatewayBind',{
        gatewayId: this.eid,
        subInfo: subInfo,
      }).then(res => {
        this.loading = false
        this.$message.success('绑定成功')
        this.$router.push("/iotEquipment");
      }).catch(err => {
        this.loading = false
        this.$message.error('绑定失败')
      })
    },

    /** 绑定子设备 **/
    bindModel() {
      this.loadSupplier()
      this.deviceDrawer = true
    },

    // 获取供应商
    loadSupplier() {
      this.$ajax.post('iotSupplierQuery').then(res => {
        if(res.data.length>0) {
          setTimeout(()=>{ //高亮选中
            this.$refs.supplierTree.setCurrentKey(res.data[0].supplierCode)
          },200)
          this.supplierData = res.data
          this.loadBrand(res.data[0].supplierCode)

          this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
            if (this.tableSelectList.find(item => item.eid == v.eid)) {
              v.checked = true
            }else {
              v.checked = false
            }
          })
        }
      })
    },

    // 获取供应商下品牌
    loadBrand(supplierCode) {
      this.$ajax.post('supplierDeviceQuery',{
        supplierCode: supplierCode
      }).then(res => {
        if(res.data.length>0) {
          this.brandLoading = true
          setTimeout(()=>{ //高亮选中
            this.$refs.brandTree.setCurrentKey(res.data[0].equipmentBrandCode)
            this.brandLoading = false
          },200)
          this.brandData = res.data
          this.loadSupplierByEquipment(res.data[0].supplierCode, res.data[0].equipmentBrandCode)

          this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
            if (this.tableSelectList.find(item => item.eid == v.eid)) {
              v.checked = true
            }else {
              v.checked = false
            }
          })
        }
      })
    },

    // 获取根据供应商、品牌筛选出的设备
    loadSupplierByEquipment(supplierCode, equipmentBrandCode) {
      this.tableSubDeviceLoading = true
      this.$ajax.post('supplierByEquipment',{
        supplierCode: supplierCode,
        equipmentBrandCode: equipmentBrandCode,
        pageNum: 0,
        pageSize: 0
      }).then(res => {
        res.data.forEach(item => {
          item.checked = false
          item.deviceAlias = item.eid
        })
        this.tableDataCp = res.data
        this.tableSubDeviceLoading = false
        
        this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
          if (this.tableSelectList.find(item => item.eid == v.eid)) {
            v.checked = true
          }else {
            v.checked = false
          }
        })
      }).catch(err=>{
        this.tableSubDeviceLoading = false
      })
    },

    // 点击供应商子项
    supplierClick(data) {
      this.brandData = []
      this.tableDataCp = []
      this.loadBrand(data.supplierCode)
    },

    // 点击品牌子项
    brandClick(data) {
      this.tableDataCp = []
      this.loadSupplierByEquipment(data.supplierCode, data.equipmentBrandCode)
    },

    // 勾选设备
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },

    // 勾选完毕点击确定
    deviceDrawerOK() {
      this.deviceTableData = []
      if(this.tableSelectList.length==0) return this.$message.error('请选择需要绑定的设备')

      this.tableSelectList.forEach(item=>{
        if(item.eid==this.eid) {
          return this.$message.error('不能绑定相同设备')
        }else {
          this.deviceTableData.push(item)
          this.deviceDrawer = false
        }
      })
    },

    // 删除巡视点信息
    dltDevice(row){
      this.deviceTableData.forEach((item, index) => {
        if (item.id == row.id) {
          this.deviceTableData.splice(index, 1)
        }
      })
      this.tableSelectList.forEach((v,k) => {
        if (v.id == row.id) {
          this.tableSelectList.splice(k, 1)
        }
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
        this.$router.push('/iotEquipment')
    } else {
      this.row = this.$route.params.row
    }
    this.eid = this.row.eid
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1200px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  margin-top: 20px;
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}

/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>